import {reqStatistics} from "@/api/f/Statistics";

// export const     //去标签页
//     const goTagPage = (item:any,id:any)=>{
//     console.log("获取的之是",item,id)
//     let isAlgorithm = item.difficulty==0?"0":'1'
//     reqStatistics({isAlgorithm}).then(res => {
//         if (res.status) {
//             nav('/fMainPage/articleTagItem',{state:{tagList:res.data.tagList,id,isAlgorithm}})
//         }
//     })
//
// }
export const adminUserId='4500fbd3-5e3c-407b-b158-51e1ed19ee3a'
