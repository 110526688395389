import {Button, Modal} from "antd";
import styles from './index.module.css'
import {useState} from "react";

const UsageSpecifications = () => {
    const [isUseSpecOpen, setIsUseSpecOpen] = useState(false);
    const showUserSpecModal = () => {
        setIsUseSpecOpen(true);
        // document.body.style.overflow = 'hidden';
        // document.body.style.height = '100vh';
    };
    const handleUserSpecOk = () => {
        setIsUseSpecOpen(false);
        // document.body.style.height = 'auto';
    };
    const handleUserSpecCancel = () => {
        setIsUseSpecOpen(false);
        // document.body.style.height = 'auto';
    };
    return (
        <div className={styles['word-tip']}>
            {/*<span className={styles['userProtocol']} onClick={showUserSpecModal}>使用规范</span>*/}
            {/*<Modal className={styles['modal-style']} footer={null} title="使用规范" open={isUseSpecOpen}*/}
            {/*       onOk={handleUserSpecOk} onCancel={handleUserSpecCancel}>*/}
            {/*    <div className={styles['field']}>*/}
            {/*        <p>本站属于个人技术与生活网站。为了维护良好的社区氛围，本站管理员将依据《用户协议》和本规范中的条款对注册本站的用户和发布在本站的内容进行管理。采取的措施包括但不限于更改或删除用户发布的内容、暂停或终止用户使用本站的权利。</p>*/}
            {/*        <p>所有有意的降低内容质量、伤害社区氛围的行为（下称「破坏」）都是不能容忍的。多数情况下社区中的其他用户会优先选择对破坏行为做出劝诫、建议和引导，本站管理员也会先发出警告并给予用户改正自己不当行为的机会，但不表示本站管理员在采取任何措施前必须首先发出警告。</p>*/}
            {/*        <h4 className={`${styles['ui']} ${styles['header']}`}>内容规范</h4>*/}
            {/*        <p>本站不允许发布以下内容（内容包括使用本站的留言、评论等各类功能发布的文本信息、图片、音视频等多媒体信息）：</p>*/}
            {/*        <ol>*/}
            {/*            <li>违反中国相关法律法规的行为和内容：*/}
            {/*                <ul>*/}
            {/*                    <li>反对宪法所确定的基本原则；</li>*/}
            {/*                    <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</li>*/}
            {/*                    <li>损害国家荣誉和利益；</li>*/}
            {/*                    <li>煽动民族仇恨、民族歧视，破坏民族团结；</li>*/}
            {/*                    <li>破坏国家宗教政策，宣扬邪教和封建迷信；</li>*/}
            {/*                    <li>散布谣言，扰乱社会秩序，破坏社会稳定；</li>*/}
            {/*                    <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪；</li>*/}
            {/*                    <li>侮辱或者诽谤他人，侵害他人合法权益；</li>*/}
            {/*                    <li>含有法律、行政法规禁止的其他内容的信息。</li>*/}
            {/*                </ul>*/}
            {/*            </li>*/}
            {/*            <li>不友善内容：*/}
            {/*                <ul>*/}
            {/*                    <li>羞辱、谩骂、攻击本站用户或其他不尊重本站用户的内容；</li>*/}
            {/*                    <li>臆测、羞辱、谩骂、攻击或其他不尊重本站用户在本站的留言、评论等内容；</li>*/}
            {/*                    <li>骚扰、恐吓、威胁、诽谤本站用户，或者其他发布在本站并对其他用户造成严重影响的内容；</li>*/}
            {/*                    <li>恶意煽动本站用户攻击、孤立或以其他手段不友善地对待其他用户的内容；</li>*/}
            {/*                    <li>因民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类而歧视、侮辱、攻击他人的内容；</li>*/}
            {/*                    <li>泄露他人隐私，或者其他侵犯他人合法权益的内容。</li>*/}
            {/*                </ul>*/}
            {/*            </li>*/}
            {/*            <li>非建设性提问：*/}
            {/*                <ul>*/}
            {/*                    <li>包含用词错误、语法错误、歧义或问题所指不明确；</li>*/}
            {/*                    <li>包含主观个人判断或基于未经证实的传言；</li>*/}
            {/*                    <li>作业、论文等需要代为完成的个人任务，未经思考、可以使用搜索引擎轻松获取解答的问题；</li>*/}
            {/*                    <li>过于个人化、场景化，发生在特定时间、空间的不具备普遍性的问题；</li>*/}
            {/*                    <li>和已有问题重复。</li>*/}
            {/*                </ul>*/}
            {/*            </li>*/}
            {/*        </ol>*/}
            {/*        <p>在上述规则之外，本站有权决定用户发布在本站上的内容的展示形式，包括但不限于在特定页面上隐藏、部分隐藏或显示特定内容，改变内容的排序，改变文本内容的排版、多媒体内容的加载和播放状态。</p>*/}
            {/*        <p>在本站上可由多人参与编辑的的内容，包括但不限于问题及补充说明、答案总结、话题描述、话题结构。除遵守上述内容规范外，社区用户有权基于社区共识对内容做出更改、隐藏、锁定、删除等各类操作。</p>*/}
            {/*        <h4 className={`${styles['ui']} ${styles['header']}`}>使用规范</h4>*/}
            {/*        <p>本站不允许以下对本站提供的产品和服务的使用方式：</p>*/}
            {/*        <ol>*/}
            {/*            <li>重复发布相似内容（包括但不限于留言、评论等）；</li>*/}
            {/*            <li>重复注册、拥有多个本站用户帐号；</li>*/}
            {/*            <li>使用任何手段操纵本站的内容排序，利用算法漏洞影响本站上内容显示（包括但不限于使用程序或多人合作影响回复排序和折叠状态、影响搜索结果排序、降低公共编辑内容的质量）；</li>*/}
            {/*            <li>在公共编辑区域清空内容、部分删除重要内容、使用无关信息替换原有内容、移动内容位置等降低公共编辑内容质量的操作；</li>*/}
            {/*            <li>破坏本站产品的运营安全或服务质量、影响其他用户使用体验的行为；</li>*/}
            {/*            <li>故意利用本站的产品缺陷和规则漏洞进行任何违背本站原则和社区共识的操作。</li>*/}
            {/*        </ol>*/}
            {/*        <h4 className={`${styles['ui']} ${styles['header']}`}>用户信息规范</h4>*/}
            {/*        <p>本站不强制用户使用实名，不要求用户提供个人身份信息的有效证明。用户可以使用自己有较长时间使用经历、被他人所熟识的个人代号或网络*/}
            {/*            ID。</p>*/}
            {/*        <p>用户选取的用户名和发布的个人信息不允许出现以下情形：</p>*/}
            {/*        <ol>*/}
            {/*            <li>用户名包含个人的职业、头衔、公司、组织、产品、行业等信息；</li>*/}
            {/*            <li>用户名包含形容词、描述性词语或短句；</li>*/}
            {/*            <li>用户名使用日常称谓、日常物品名称、通用词汇；</li>*/}
            {/*            <li>用户名包含数字、特殊符号、无意义的字符；</li>*/}
            {/*            <li>冒充他人身份，或提供混淆、误导性的信息；</li>*/}
            {/*            <li>个人资料不实、伪造身份（包括但不限于性别、职业、教育背景等）。</li>*/}
            {/*        </ol>*/}
            {/*        <p>对于违反上述规范则的用户，本站保留予以警告、要求修改用户名、重置用户信息和停用帐号的权利。</p>*/}
            {/*        <h4 className={`${styles['ui']} ${styles['header']}`}>申诉</h4>*/}
            {/*        <p>如果您对本站基于本规范做出的处理有异议，请向 25238909336@qq.com 发送邮件提交您的申诉。</p>*/}
            {/*    </div>*/}
            {/*    <div className={styles['btn']}>*/}
            {/*        <Button type="primary" onClick={handleUserSpecCancel}>我已知晓</Button>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            <div className={styles['field']}>

                <h4>版权协议最新更新时间：2024年6月13日</h4>
                <p>本站属于个人技术与生活网站。为了维护良好的社区氛围，本站管理员将依据《用户协议》和本规范中的条款对注册本站的用户和发布在本站的内容进行管理。采取的措施包括但不限于更改或删除用户发布的内容、暂停或终止用户使用本站的权利。</p>
                <p>所有有意的降低内容质量、伤害社区氛围的行为（下称「破坏」）都是不能容忍的。多数情况下社区中的其他用户会优先选择对破坏行为做出劝诫、建议和引导，本站管理员也会先发出警告并给予用户改正自己不当行为的机会，但不表示本站管理员在采取任何措施前必须首先发出警告。</p>
                <h4 className={`${styles['ui']} ${styles['header']}`}>内容规范</h4>
                <p>本站不允许发布以下内容（内容包括使用本站的留言、评论等各类功能发布的文本信息、图片、音视频等多媒体信息）：</p>
                <ol>
                    <li>违反中国相关法律法规的行为和内容：
                        <ul>
                            <li>反对宪法所确定的基本原则；</li>
                            <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</li>
                            <li>损害国家荣誉和利益；</li>
                            <li>煽动民族仇恨、民族歧视，破坏民族团结；</li>
                            <li>破坏国家宗教政策，宣扬邪教和封建迷信；</li>
                            <li>散布谣言，扰乱社会秩序，破坏社会稳定；</li>
                            <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪；</li>
                            <li>侮辱或者诽谤他人，侵害他人合法权益；</li>
                            <li>含有法律、行政法规禁止的其他内容的信息。</li>
                        </ul>
                    </li>
                    <li>不友善内容：
                        <ul>
                            <li>羞辱、谩骂、攻击本站用户或其他不尊重本站用户的内容；</li>
                            <li>臆测、羞辱、谩骂、攻击或其他不尊重本站用户在本站的留言、评论等内容；</li>
                            <li>骚扰、恐吓、威胁、诽谤本站用户，或者其他发布在本站并对其他用户造成严重影响的内容；</li>
                            <li>恶意煽动本站用户攻击、孤立或以其他手段不友善地对待其他用户的内容；</li>
                            <li>因民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类而歧视、侮辱、攻击他人的内容；</li>
                            <li>泄露他人隐私，或者其他侵犯他人合法权益的内容。</li>
                        </ul>
                    </li>
                    <li>非建设性提问：
                        <ul>
                            <li>包含用词错误、语法错误、歧义或问题所指不明确；</li>
                            <li>包含主观个人判断或基于未经证实的传言；</li>
                            <li>作业、论文等需要代为完成的个人任务，未经思考、可以使用搜索引擎轻松获取解答的问题；</li>
                            <li>过于个人化、场景化，发生在特定时间、空间的不具备普遍性的问题；</li>
                            <li>和已有问题重复。</li>
                        </ul>
                    </li>
                </ol>
                <p>在上述规则之外，本站有权决定用户发布在本站上的内容的展示形式，包括但不限于在特定页面上隐藏、部分隐藏或显示特定内容，改变内容的排序，改变文本内容的排版、多媒体内容的加载和播放状态。</p>
                <p>在本站上可由多人参与编辑的的内容，包括但不限于问题及补充说明、答案总结、话题描述、话题结构。除遵守上述内容规范外，社区用户有权基于社区共识对内容做出更改、隐藏、锁定、删除等各类操作。</p>
                <h4 className={`${styles['ui']} ${styles['header']}`}>使用规范</h4>
                <p>本站不允许以下对本站提供的产品和服务的使用方式：</p>
                <ol>
                    <li>重复发布相似内容（包括但不限于留言、评论等）；</li>
                    <li>重复注册、拥有多个本站用户帐号；</li>
                    <li>使用任何手段操纵本站的内容排序，利用算法漏洞影响本站上内容显示（包括但不限于使用程序或多人合作影响回复排序和折叠状态、影响搜索结果排序、降低公共编辑内容的质量）；</li>
                    <li>在公共编辑区域清空内容、部分删除重要内容、使用无关信息替换原有内容、移动内容位置等降低公共编辑内容质量的操作；</li>
                    <li>破坏本站产品的运营安全或服务质量、影响其他用户使用体验的行为；</li>
                    <li>故意利用本站的产品缺陷和规则漏洞进行任何违背本站原则和社区共识的操作。</li>
                </ol>
                <h4 className={`${styles['ui']} ${styles['header']}`}>用户信息规范</h4>
                <p>本站不强制用户使用实名，不要求用户提供个人身份信息的有效证明。用户可以使用自己有较长时间使用经历、被他人所熟识的个人代号或网络
                    ID。</p>
                <p>用户选取的用户名和发布的个人信息不允许出现以下情形：</p>
                <ol>
                    <li>用户名包含个人的职业、头衔、公司、组织、产品、行业等信息；</li>
                    <li>用户名包含形容词、描述性词语或短句；</li>
                    <li>用户名使用日常称谓、日常物品名称、通用词汇；</li>
                    <li>用户名包含数字、特殊符号、无意义的字符；</li>
                    <li>冒充他人身份，或提供混淆、误导性的信息；</li>
                    <li>个人资料不实、伪造身份（包括但不限于性别、职业、教育背景等）。</li>
                </ol>
                <p>对于违反上述规范则的用户，本站保留予以警告、要求修改用户名、重置用户信息和停用帐号的权利。</p>
                <h4 className={`${styles['ui']} ${styles['header']}`}>申诉</h4>
                <p>如果您对本站基于本规范做出的处理有异议，请向 25238909336@qq.com 发送邮件提交您的申诉。</p>
            </div>
        </div>
    )
}
export default UsageSpecifications
