import styles from "@/pages/f/faceClassic/index.module.css";
import React, {useRef} from "react";
import Prism from "prismjs";
import BlogItemStyles from '../../articleMangement/blogItem/index.module.css'
import 'prismjs/themes/prism-okaidia.css'
import 'prismjs/components/'
import "prismjs/components/prism-java" //引入Java样式
import "prismjs/components/prism-bash.min.js" //引入Bash样式
import "prismjs/components/prism-typescript" //引入TypeScript样式
import "prismjs/plugins/line-numbers/prism-line-numbers.min.css" //行号插件的样式
import "prismjs/plugins/line-numbers/prism-line-numbers.min.js"
import "prismjs/plugins/toolbar/prism-toolbar.min.css"
import "prismjs/plugins/toolbar/prism-toolbar.min.js"
import "prismjs/plugins/show-language/prism-show-language.min.js"
const AnswerItem=(props:any)=>{
    const content: any = {
        __html: props.data.answer,
    };
    const codeElement: any = useRef<HTMLElement | null>(null);
    setTimeout(() => Prism.highlightAll(), 0);
    return (
        <div className={`${BlogItemStyles['myStyleOnly']} ${styles['myWidth']}`}>
            <div className={`${BlogItemStyles['my-line-numbers']} line-numbers pre-mac`}
                 ref={codeElement}
                 dangerouslySetInnerHTML={content}>
            </div>
        </div>
    )
}
export default AnswerItem