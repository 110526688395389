import { Editor } from '@tinymce/tinymce-react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { message } from "antd";
import styles from './index.module.css'
import {LoadingOutlined} from "@ant-design/icons";
const RichText = forwardRef(({ data }: { data: string }, ref: any) => {
    const editorRef = useRef<any>(null);
    const [editorInitialized, setEditorInitialized] = useState(false);  // 新增状态标志编辑器初始化是否完成

    // 使用 useImperativeHandle 暴露给父组件的方法
    useImperativeHandle(ref, () => ({
        getEditorContent: () => {
            if (editorRef.current) {
                // 返回编辑器的内容
                return editorRef.current.getContent();
            }
            return "";
        }
    }));

    useEffect(() => {
        if (editorInitialized && editorRef.current) {
            if (data) {
                // 如果 editor 已经初始化，可以通过 editorRef 直接操作数据
                editorRef.current.setContent(data);  // 回显父组件传递的数据
            }
        }
    }, [data, editorInitialized]);  // 依赖 editorInitialized 和 data

    const handleEditorInit = (_evt: any, editor: any) => {
        editorRef.current = editor;
        setEditorInitialized(true);  // 编辑器初始化完成后，更新状态
    };

    return (
        <div>
            {!editorInitialized && <div className={styles['loadingBox']}>编辑器加载中 <LoadingOutlined /></div>

            }
            <Editor
                apiKey="1675407wy1e3fwoi4glhtsgt0tmi16m1u7zl3bhu1lf3qa5e"
                onInit={handleEditorInit}
                initialValue="留下点什么吧~"
                init={{
                    codesample_languages: [
                        { text: 'HTML/XML', value: 'markup' },
                        { text: 'CSS', value: 'css' },
                        { text: 'JavaScript', value: 'javascript' },
                        { text: 'Java', value: 'java' },
                        { text: 'Bash', value: 'bash' },
                        { text: 'TypeScript', value: 'typescript' },
                        { text: 'SQL', value: 'sql' },
                    ],
                    language: 'zh_CN',
                    height: 500,
                    plugins: [
                        'accordion', 'emoticons', 'codesample', 'code', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'accordion emoticons codesample code undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </div>
    );
});

export default RichText;
