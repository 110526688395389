import {message, Radio, Timeline} from "antd";
import {useEffect, useRef, useState} from "react";
import styles from './index.module.css';
import {Image} from 'antd';
import {ReactComponent as TimesIcon} from "./img/times.svg";
import { imgListO} from "@/utils/tools";

// const TimeAlbum = () => {
//     const timeInfo = [
//         {id: '1', time: '2024-02-06 星期三', description: "天坛一日游"},
//         {id: '2', time: '2024-02-06 星期三', description: "北海公园"},
//         {id: '3', time: '2024-02-06 星期三', description: "故宫"},
//         {id: '4', time: '2024-02-06 星期三', description: "南楼古巷"},
//         {id: '5', time: '2024-02-06 星期三', description: "什刹海"},
//         {id: '6', time: '2024-02-06 星期三', description: "鼓楼"}
//     ];
//
//     const [columns, setColumns] = useState(3);
//
//     const computedFn = (timeInfo: any) => {
//         return timeInfo.map((item: any) => ({
//             label: item.time,
//             children: (
//                 <div className={styles['address']}>
//                     <span className={styles['addressName']}>{item.description}</span>
//                     <span className={styles['times']}><TimesIcon/>1</span>
//                 </div>
//             )
//         }));
//     };
//
//     const imgList: any = imgListO
//
//     const radioChange = (columns: any) => {
//         console.log("列数是", columns);
//     };
//
//     const itemRefs: any = useRef({});
//     let num = 0
//     useEffect(() => {
//         const observer = new IntersectionObserver((entries) => {
//             entries.forEach((entry) => {
//                 if (entry.isIntersecting) {
//                     const img:any = entry.target.querySelector('img'); // 从li中查询img
//                     if (img) {
//                         img.src = img.dataset.src; // 设置图片源
//                         img.onload = () => {
//                             img.style.filter = 'none'; // 清除模糊效果
//                             img.style.opacity = '1'; // 清除模糊效果
//                             message.success("图片加载成功"+num++);
//                         };
//                         observer.unobserve(entry.target); // 观察完后取消观察
//                     }
//                 }
//             });
//         }, { rootMargin: '10px 0px -10px 0px' });
//
//         imgList.forEach((item:any) => {
//             const el = itemRefs.current[item.id];
//             if (el) {
//                 const img = el.querySelector('img');
//                 if (img) {
//                     img.dataset.src = item.url; // 将图片地址存储到data-src中
//                     observer.observe(el); // 观察该li元素
//                 }
//             }
//         });
//
//         return () => {
//             observer.disconnect();
//         };
//     }, []);
//
//
//     return (
//         <div>
//             <div className={styles['content']}>
//                 <div className={styles['content-left']}>
//                     <Timeline className={styles['timeLineStyle']} mode='left' items={computedFn(timeInfo)}/>
//                 </div>
//                 <div className={styles['content-right']}>
//                     <Radio.Group onChange={(e) => {
//                         setColumns(e.target.value);
//                         radioChange(e.target.value);
//                     }} defaultValue={3} size="large">
//                         <Radio.Button value={3}>三列</Radio.Button>
//                         <Radio.Button value={4}>四列</Radio.Button>
//                         <Radio.Button value={5}>五列</Radio.Button>
//                     </Radio.Group>
//                     <ul style={{display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`}}
//                         className={styles['img-ul']}>
//                         {imgList.map((item: any) => (
//                             <li     ref={el => itemRefs.current[item.id] = el} key={item.id} className={styles['image-wrapper']}>
//                                 <Image
//                                     src="" // 初始为空
//                                     data-src={item.url} // 图片地址存储在data-src
//                                     // style={{ filter: 'blur(10px)', transition: 'filter 0.5s' }} // 初始模糊效果
//
//                                 />
//
//                                 {/*<Image*/}
//                                 {/*    width={200}*/}
//                                 {/*    src={item.url}*/}
//                                 {/*    placeholder={*/}
//                                 {/*        <Image*/}
//                                 {/*            preview={false}*/}
//                                 {/*            src={item.url}*/}
//                                 {/*            width={200}*/}
//                                 {/*        />*/}
//                                 {/*    }*/}
//                                 {/*/>*/}
//
//                                 <div className={styles['footer-tip']}>
//                                     北极熊
//                                     <div className={styles['littleTip']}>
//                                         <div>shoting by 木瓜一块八</div>
//                                         <div>shoting time 2024/08/09</div>
//                                     </div>
//                                 </div>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// };

const TimeAlbum=()=>{
    return (
        <div></div>
    )
}

export default TimeAlbum;

