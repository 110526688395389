import requests from "@/utils/requests";

//获取面经列表
export const reqGetFaceClassicList=(data:any)=>{
    return requests({
        url:'/api/faceClassic/getFaceClassicList',
        method:'post',
        data
    })
}
//添加面经
export const reqAddFaceClassic=(data:any)=>{
    return requests({
        url:'/api/faceClassic/addFaceClassic',
        method:'post',
        data
    })
}
//修改面经
export const reqUpdateFaceClassic=(data:any)=>{
    return requests({
        url:'/api/faceClassic/updateFaceClassic',
        method:'post',
        data
    })
}
//删除面经
export const reqDeleteFaceClassic=(data:any)=>{
    return requests({
        url:'/api/faceClassic/deleteFaceClassic',
        method:'post',
        data
    })
}
//添加面经类型
export const reqAddFaceClassicType=(data:any)=>{
    return requests({
        url:'/api/faceClassic/addFaceclassicType',
        method:'post',
        data
    })
}
//获取面经类型列表
export const reqgetFaceClassicTypeList=(data:any)=>{
    return requests({
        url:'/api/faceClassic/getFaceclassicTypeList',
        method:'post',
        data
    })
}