import styles from "./index.module.css";

const BtnLoading=(props:any)=>{
    const containerStyle = {
        height: `${props.data}px`,
        width: `${props.data}px`,
        mask: `radial-gradient(circle calc(${props.data}px / 2 - 5px), transparent 99%, #2086ee 100%)`,
    };

    return <div style={containerStyle} className={styles['container']}></div>;
}
export default BtnLoading;