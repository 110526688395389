//主题色
// export const themeColor='#2185d0 425aef'
// export const themeColor='#6f42ca'
// export const themeColor='#425aef'
export const themeColor='#2e51b3'


//随机颜色
//浅色系(友链)
 export  const randomColorDLight=["#f5e0e3", "#E0f5fb", "#fbffc9", "#efe2fd", "#e5f9da", "#ffe7de", "#e0eaeb", "#e7c8f0", "#e5eabf", "#c2ece6", "#f2e3cb"];
//深色系
export  const randomColorDeep=["#716f00","#2bca00","#409a05","#0bb86d","#00bd8d","#d75443","#608e6e","#6936ff","#ff11ea","#c615bc","#2e7e8c","#774fd1"]
//电脑加载效果
export const computerLoading ='https://img.lblssl.cn/other/loading/newLoading.gif'
//图片加载效果(房子)
export  const loadingImg='https://img.lblssl.cn/other/loadingImg.gif'

