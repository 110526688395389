import {lazy} from "react";
import RobotMangement from "@/pages/b/admin/RobotMangement";
import WordMangement from "@/pages/b/admin/WordMangement";
import UserProtocol from "@/pages/f/commonCompnents/Footer/userProtocol";
import UsageSpecifications from "@/pages/f/commonCompnents/Footer/usageSpecifications";
import Cookies from "@/pages/f/commonCompnents/Footer/Cookies";
import TimeAlbum from "@/pages/f/secretBase/loveMain/timeAlbum";
import FootPrint from "@/pages/f/secretBase/loveMain/footPrint";
import FaceClassic from "@/pages/f/faceClassic";
import FaceClassicMangement from "@/pages/b/admin/faceClassicMangement";
import FaceClassicType from "@/pages/b/admin/faceClassicMangement/faceClassicType";
import FaceClassicList from "@/pages/b/admin/faceClassicMangement/faceClassicList";
import OutIndex from "@/pages/b/admin/faceClassicMangement/outIndex";

const Friends = lazy(() => import("@/pages/f/friends"));
const FriendsMangement = lazy(() => import("@/pages/b/admin/friendsMangement"));
const ArticleStatistics = lazy(() => import("@/pages/f/statistics/articleStatistics"));
const ArticleOverview = lazy(() => import("@/pages/f/articleMangement/articleOverview"));
const ArticleTag = lazy(() => import("@/pages/f/articleMangement/articleTag"));
const ArticleTagItem = lazy(() => import("@/pages/f/articleMangement/articleTagItem"));
const TagMangement = lazy(() => import("@/pages/b/admin/BlogMangement/tagMangement"));
const HotNews = lazy(() => import("@/pages/f/HotNews"));
const LastLogin = lazy(() => import("@/pages/f/commonCompnents/lastLogin"));
const SecretBase = lazy(() => import("@/pages/f/secretBase"));
const LoveMain = lazy(() => import("@/pages/f/secretBase/loveMain"));
const Play = lazy(() => import("@/pages/f/secretBase/loveMain/play"));
const Weekend = lazy(() => import("@/pages/f/secretBase/loveMain/weekend"));
const Algorithm = lazy(() => import("@/pages/f/algorithm"));
const AlgorithmStatistics = lazy(() => import("@/pages/f/algorithm/algorithmStatistics"));
const AlgorithmOverview = lazy(() => import("@/pages/f/articleMangement/articleOverview/algorithmOverview"));

const UpdateLogMangement = lazy(() => import("@/pages/b/updateLogMangement"));
const LogList = lazy(() => import("@/pages/b/updateLogMangement/LogList"));
const SuggestionMangement = lazy(() => import("@/pages/b/admin/suggestionMangement"));
const UserMangement = lazy(() => import("src/pages/b/admin/userMangement"));
const WebsiteNavigation = lazy(() => import("@/pages/f/websiteNavigation"));
const WebNavMangement = lazy(() => import("src/pages/b/admin/WebNavMangement"));
const PublishBlog = lazy(() => import("@/pages/b/admin/BlogMangement/publishBlog"));
const BBlogList = lazy(() => import("@/pages/b/admin/BlogMangement/blogList"));
const DevelopmentLogs = lazy(() => import("@/pages/f/developmentLogs"));
const MessageBoard = lazy(() => import("@/pages/f/messageBoard/index"));
const BlogMangement = lazy(() => import("@/pages/b/admin/BlogMangement"));
const HomePage = lazy(() => import("src/pages/b/HomePage"));
const WorkSpace = lazy(() => import("src/pages/b/WorkSpace"));
const About = lazy(() => import("src/pages/b/About"));
const AnalysisMain = lazy(() => import("@/pages/b/algorithmAnalysis/AnalysisMain"));
const Plan = lazy(() => import("@/pages/b/Plan/Plan"));
const Work = lazy(() => import("src/pages/b/Work"));
const LikeCompany = lazy(() => import("@/pages/b/Work/likeCompany"));
const StatisticsScreen = lazy(() => import("@/pages/b/algorithmAnalysis/StatisticsScreen"));
const AnalysisMangement = lazy(() => import("@/pages/b/algorithmAnalysis/analysisMangement"));
const LearningLogs = lazy(() => import("src/pages/b/LearningLogs"));
const LogHistory = lazy(() => import("@/pages/b/LearningLogs/LogHistory"));
const LogCreate = lazy(() => import("@/pages/b/LearningLogs/LogCreate"));
const ProgressOfLearning = lazy(() => import('@/pages/b/ProgressOfLearning/index'));
const HistoryOfLearning = lazy(() => import("src/pages/b/HistoryOfLearning"));
const CompanyMangement = lazy(() => import("@/pages/b/Work/companyMangement"));
const PermissionMangement = lazy(() => import("@/pages/b/admin/permissionMangement/index"));
const AddPermission = lazy(() => import("@/pages/b/admin/permissionMangement/addPermission"));
const PermissionTreeMangement = lazy(() => import("@/pages/b/admin/permissionMangement/permissionTreeMangement"));
const RolePermissionConfig = lazy(() => import("@/pages/b/admin/permissionMangement/rolePermissionConfig"));
const Test = lazy(() => import("@/pages/f/test/index"));
const FHomePage = lazy(() => import("@/pages/f/HomePage"));
const FAbout = lazy(() => import("@/pages/f/fAbout"));
const FMainPage = lazy(() => import("@/pages/f"));
const Movies = lazy(() => import("@/pages/f/movies"));
const Daily = lazy(() => import("@/pages/f/Daily"));
const PersonalInfo = lazy(() => import("@/pages/f/personalInfo"));
const BlogItem = lazy(() => import("@/pages/f/articleMangement/blogItem"));

const MLogin = lazy(() => import("@/pages/f/commonCompnents/TopHeader/mLogin/index"));
const MRegister = lazy(() => import('@/pages/f/commonCompnents/TopHeader/register/index'));
const Register = lazy(() => import("../components/Register/index"));
const ContentPage = lazy(() => import("../pages/b/ContentPage"));

const routes = [
    {
        path: '/',
        component: FMainPage,
    },
    {
        path: '/faceClassic',
        component: FaceClassic
    },
    {
        path: '/fMainPage',
        component: FMainPage,
        children: [
            {path: 'rlogin', component: LastLogin, meta: {showFooter: false}},
            {path: 'fHomePage', title: '首页-木瓜一块八', component: FHomePage, meta: {showFooter: true}},
            {path: 'test', component: Test, meta: {showFooter: false}},
            {path: 'fAbout', title: '本站概览', component: FAbout, meta: {showFooter: true}},
            {path: 'movies', title: '影视', component: Movies, meta: {showFooter: true}},
            {path: 'daily', title: '', component: Daily, meta: {showFooter: false}},
            {path: 'blogItem', title: '文章详情', component: BlogItem, meta: {showFooter: true}},
            {path: 'messageBoard', title: '留言板', component: MessageBoard, meta: {showFooter: true}},
            {path: 'developmentLogs', title: '', component: DevelopmentLogs, meta: {showFooter: true}},
            {
                path: 'websiteNavigation',
                title: '网站导航',
                component: WebsiteNavigation,
                meta: {showFooter: true, hiddenFooter: true}
            },
            {path: 'friends', title: '朋友们', component: Friends, meta: {showFooter: true}},
            {path: 'articleStatistics', title: '文章统计', component: ArticleStatistics, meta: {showFooter: true}},
            {path: 'articleOverview', title: '文章总览', component: ArticleOverview, meta: {showFooter: true}},
            {path: 'articleTag', title: '文章标签', component: ArticleTag, meta: {showFooter: true}},
            {path: 'articleTagItem', title: '', component: ArticleTagItem, meta: {showFooter: true}},
            {path: 'hotNews', title: '热搜', component: HotNews, meta: {showFooter: true}},
            {path: 'secretBase', title: '', component: SecretBase, meta: {showFooter: false}},
            {path: 'algorithm', title: '', component: Algorithm, meta: {showFooter: false}},
            {path: 'algorithmStatistics', title: '', component: AlgorithmStatistics, meta: {showFooter: false}},
            {path: 'algorithmOverview', title: '算法总览', component: AlgorithmOverview, meta: {showFooter: false}},
            {
                path: 'loveMain', title: '', component: LoveMain, meta: {showFooter: false},
                children: [
                    {path: 'play', title: '', component: Play, meta: {showFooter: false}},
                    {path: 'weekend', title: '', component: Weekend, meta: {showFooter: false}},
                    {path: 'timeAlbum', title: '', component: TimeAlbum, meta: {showFooter: false}},
                    {path: 'footPrint', title: '', component: FootPrint, meta: {showFooter: false}},
                ]
            },
            {path: 'userProtocol', component: UserProtocol, title: '用户协议', meta: {showFooter: true}},
            {path: 'usageSpecifications', component: UsageSpecifications, title: '使用规范', meta: {showFooter: true}},
            {path: 'cookies', component: Cookies, title: 'Cookies', meta: {showFooter: true}},

        ]
    },
    {path: '/personalInfo', component: PersonalInfo},
    {path: '/mLogin', component: MLogin},
    {path: '/mRegister', component: MRegister},
    {path: '/register', component: Register, meta: {fullPath: '/register'}},
    {
        path: '/customer',
        title: '用户',
        component: ContentPage,
        meta: {fullPath: '/customer'},
        children: [
            {
                path: 'homePage',
                title: '首页',
                component: HomePage,
                meta: {icon: 'HomeOutlined', fullPath: '/customer/homePage'}
            },
            {
                path: 'workSpace',
                component: WorkSpace,
                title: '工作台',
                meta: {icon: 'DesktopOutlined', fullPath: '/customer/workSpace'}
            },
            {
                path: 'progressOfLearning',
                component: ProgressOfLearning,
                title: '学习进度',
                meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/customer/progressOfLearning'}
            },
            {
                path: 'analysisAll',
                component: AnalysisMain,
                title: '分析统计',
                meta: {icon: 'LineChartOutlined', fullPath: '/customer/analysisAll'},
                children: [
                    {
                        path: 'statisticsScreen',
                        title: '统计大屏',
                        component: StatisticsScreen,
                        meta: {hiddenFooter: true, fullPath: '/customer/analysisAll/statisticsScreen'}
                    },
                    {
                        path: 'plan',
                        component: Plan,
                        title: '计划信息',
                        meta: {hiddenFooter: true, fullPath: '/customer/analysisAll/plan'}
                    },
                    {
                        path: 'analysisMangement',
                        component: AnalysisMangement,
                        title: '分析管理',
                        meta: {hiddenFooter: true, fullPath: '/customer/analysisAll/analysisMangement'}
                    },
                ]
            },
            {
                path: 'work',
                component: Work,
                title: '工作',
                meta: {icon: 'CalendarOutlined', fullPath: '/customer/work'},
                children: [
                    {
                        title: '心仪Commpone',
                        path: 'LikeCompany',
                        component: LikeCompany,
                        meta: {hiddenFooter: true, fullPath: '/customer/work/LikeCompany'}
                    },
                    {
                        title: 'company管理',
                        path: 'companyMagement',
                        component: CompanyMangement,
                        meta: {hiddenFooter: true, fullPath: '/customer/work/companyMagement'}
                    },
                ]
            },
            {path: 'plan', component: Plan, title: '计划', meta: {hiddenFooter: true, fullPath: '/customer/plan'}},
            {
                path: 'learningLogs',
                component: LearningLogs,
                title: '学习日志',
                meta: {hiddenFooter: true, fullPath: '/customer/learningLogs'},
                children: [
                    {
                        path: 'logCreate',
                        component: LogCreate,
                        title: '添加记录',
                        meta: {hiddenFooter: true, fullPath: '/customer/learningLogs/logCreate'}
                    },
                    {
                        path: 'logHistory',
                        component: LogHistory,
                        title: '提交记录',
                        meta: {hiddenFooter: true, fullPath: '/customer/learningLogs/logHistory'}
                    },
                ]
            },
            {
                path: 'HistoryOfLearning',
                component: HistoryOfLearning,
                title: '学习历史',
                meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/customer/HistoryOfLearning'}
            },
            {
                path: 'about',
                component: About,
                title: '关于',
                meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/customer/about'}
            },

        ]
    },
    {
        path: "/ordinaryAdministrator",
        title: '普通管理员',
        component: ContentPage,
        meta: {fullPath: '/ordinaryAdministrator', icon: 'UserOutlined'},
        children: [
            {
                path: 'faceClassicMangement',
                component: FaceClassicMangement,
                title: '面经管理',
                meta: {hiddenFooter: true, fullPath: '/ordinaryAdministrator/faceClassicMangement'},
                children: [
                    {
                        path: 'adddFaceClassicMangement',
                        component: About,
                        title: '添加面经',
                        meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/ordinaryAdministrator/faceClassicMangement/adddFaceClassicMangement'}
                    },
                    {
                        path: 'faceClassicMangementList',
                        component: About,
                        title: '面经列表',
                        meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/ordinaryAdministrator/faceClassicMangement/faceClassicMangementList'}
                    },
                ]
            },
        ]
    },
    {
        path: '/admin',
        title: '管理员',
        component: ContentPage,
        meta: {fullPath: '/admin', icon: 'UserOutlined'},
        children: [
            {
                path: 'roleMangement',
                component: PermissionMangement,
                title: '角色管理',
                meta: {hiddenFooter: true, fullPath: '/admin/roleMangement'},
                children: [
                    {
                        path: 'permissionTreeMangement',
                        component: PermissionTreeMangement,
                        title: '权限树配置',
                        meta: {hiddenFooter: true, fullPath: '/admin/roleMangement/permissionTreeMangement'}
                    },
                    {
                        path: 'addPermission',
                        component: AddPermission,
                        title: '添加权限',
                        meta: {hiddenFooter: true, fullPath: '/admin/roleMangement/addPermission'}
                    },
                    {
                        path: 'rolePermissionConfig',
                        component: RolePermissionConfig,
                        title: '角色权限配置',
                        meta: {hiddenFooter: true, fullPath: '/admin/roleMangement/rolePermissionConfig'}
                    },
                    {
                        path: 'test',
                        component: Test,
                        title: 'test',
                        meta: {hiddenFooter: true, fullPath: '/admin/roleMangement/test'}
                    },
                ]
            },
            {
                path: 'updateLogMangement',
                component: UpdateLogMangement,
                title: '日志管理',
                meta: {hiddenFooter: true, fullPath: '/admin/updateLogMangement'},
                children: [
                    {
                        path: 'logList',
                        component: LogList,
                        title: '日志列表',
                        meta: {hiddenFooter: true, fullPath: '/admin/updateLogMangement/logList'}
                    },
                ]
            },
            {
                path: 'suggestionMangement',
                component: SuggestionMangement,
                title: '反馈管理',
                meta: {hiddenFooter: true, fullPath: '/admin/suggestionMangement'}
            },
            {
                path: 'faceClassicMangement',
                component: OutIndex,
                title: '面经管理',
                meta: {hiddenFooter: true, fullPath: '/admin/faceClassicMangement'},
                children: [
                    {
                        path: 'adddFaceClassicMangement',
                        component: FaceClassicMangement,
                        title: '添加面经',
                        meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/admin/faceClassicMangement/adddFaceClassicMangement'}
                    },
                    {
                        path: 'faceClassicType',
                        component: FaceClassicType,
                        title: '类型管理',
                        meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/admin/faceClassicMangement/faceClassicType'}

                    },
                    {
                        path: 'faceClassicList',
                        component: FaceClassicList,
                        title: '面经列表',
                        meta: {icon: 'SettingOutlined', hiddenFooter: true, fullPath: '/admin/faceClassicMangement/faceClassicList'}
                    },

                ]
            },
            {
                path: 'robotMangement',
                component: RobotMangement,
                title: '机器人管理',
                meta: {hiddenFooter: true, fullPath: '/admin/robotMangement'}
            },
            {
                path: 'wordMangement',
                component: WordMangement,
                title: '金句管理',
                meta: {hiddenFooter: true, fullPath: '/admin/wordMangement'}
            },
            {
                path: 'webNavMangement',
                component: WebNavMangement,
                title: '导航管理',
                meta: {hiddenFooter: true, fullPath: '/admin/webNavMangement'}
            },
            {
                path: 'userMangement',
                component: UserMangement,
                title: '用户管理',
                meta: {hiddenFooter: true, fullPath: '/admin/userMangement'}
            },
            {
                path: 'friendsMangement',
                component: FriendsMangement,
                title: '友链管理',
                meta: {hiddenFooter: true, fullPath: '/admin/friendsMangement'}
            },
            {
                path: 'blogMangement',
                title: '博客管理',
                component: BlogMangement,
                meta: {icon: 'HomeOutlined', fullPath: '/admin/blogMangement'},
                children: [
                    {
                        path: 'bBlogList',
                        component: BBlogList,
                        title: '博客列表',
                        meta: {hiddenFooter: true, fullPath: '/admin/blogMangement/bBlogList'}
                    },
                    {
                        path: 'publishBlog',
                        component: PublishBlog,
                        title: '发布博客',
                        meta: {hiddenFooter: true, fullPath: '/admin/blogMangement/publishBlog'}
                    },
                    {
                        path: 'tagMangement',
                        component: TagMangement,
                        title: '标签管理',
                        meta: {hiddenFooter: true, fullPath: '/admin/blogMangement/tagMangement'}
                    },
                ]
            },
        ]
    }
];
export default routes;
