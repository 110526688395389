import {Table} from "antd";
import cookiesStyles from "./cookies.module.css";
const Cookies = () => {
    const dataSource = [
        {
            key: '1',
            purpose: '授权',
            explain: '你访问我的网站时，我可通过 Cookie 提供正确信息，为你打造个性化的体验。例如：Cookie会告知你通过搜索引擎搜索的具体内容来改善文章的标题优化关键词、或者创建更符合你搜索需求的文章内容。',
        },
        {
            key: '2',
            purpose: '安全措施',
            explain: '我通过 Cookie 启用及支持安全功能，监控和防止可疑活动、欺诈性流量和违反版权协议的行为。',
        },
        {
            key: '3',
            purpose: '偏好、功能和服务',
            explain: '我使用功能性Cookies来让我记住你的偏好，或保存你向我提供的有关你的喜好或其他信息。',
        },
        {
            key: '4',
            purpose: '个性化广告',
            explain: '本站不涉及个性化广告服务',
        },
        {
            key: '5',
            purpose: '网站性能、分析和研究',
            explain: '我使用这些cookie来监控网站性能。这使我能够通过快速识别和解决出现的任何问题来提供高质量的体验。',
        },
    ];

    const columns = [
        {
            title: '用途',
            dataIndex: 'purpose',
            key: 'purpose',
        },
        {
            title: '说明',
            dataIndex: 'explain',
            key: 'explain',
        },
    ];
    const dataSource1 = [
        {
            key: '1',
            purpose: '本站未使用任何第三方cookies',
            threeProvider: '无',
        },
    ];

    const columns1 = [
        {
            title: '第三方服务商',
            dataIndex: 'threeProvider',
            key: 'threeProvider',
        },
        {
            title: '用途',
            dataIndex: 'purpose',
            key: 'purpose',
        },

    ];
    return (
        <div className={cookiesStyles['main-box']}>
            <h2>本政策的最近更新日期为：2024-03-22</h2>
            <h2>Cookies</h2>
            <p>为了确保网站和我开发的软件的可靠性、安全性和个性化，我使用 Cookies。当你接受 Cookies
                时，这有助于通过识别你的身份、记住你的偏好或提供个性化用户体验来帮助我改善网站。</p>
            <p>本政策应与我的隐私政策一起阅读，该隐私政策解释了我如何使用个人信息。</p>
            <p>如果你对我使用你的个人信息或 Cookies 的方式有任何疑问，请通过 2523890936@qq.com 与我联系。</p>
            <p>如果你想管理你的 Cookies，请按照下面“如何管理 Cookies”部分中的说明进行操作。</p>
            <h2>什么是 Cookies？</h2>
            <p>Cookies 是一种小型文本文件，当你访问网站时，网站可能会将这些文件放在你的计算机或设备上。Cookies
                会帮助网站或其他网站在你下次访问时识别你的设备。网站信标、像素或其他类似文件也可以做同样的事情。我在此政策中使用术语“Cookies”来指代以这种方式收集信息的所有文件。</p>
            <p> Cookies 提供许多功能。例如，他们可以帮助我记住你喜欢深色模式还是浅色模式，分析我网站的效果。 </p>
            <p>大多数网站使用 Cookies 来收集和保留有关其访问者的个人信息。大多数 Cookies
                收集一般信息，例如访问者如何到达和使用我的网站，他们使用的设备，他们的互联网协议地址（IP
                地址），他们正在查看的页面及其大致位置（例如，我将能够认识到你正在从北京访问我的网站）。</p>
            <h2>Cookies 的目的</h2>
            <Table pagination={false}  dataSource={dataSource} columns={columns}/>
            <h2>我的网站上的第三方Cookies</h2>
            <p>我还在我的网站上使用属于上述类别的第三方Cookies，用于以下目的：</p>
            <ul>
                <li>帮助我监控网站上的流量；</li>
                <li>识别欺诈或非人为性流量；</li>
                <li>协助市场调研；</li>
                <li>改善网站功能；</li>
                <li>监督我的版权协议和隐私政策的遵守情况。</li>
            </ul>
            <Table pagination={false} dataSource={dataSource1} columns={columns1}/>
            <h2>如何管理Cookies？</h2>
            <p>在将Cookie放置在你的计算机或设备上之前，系统会显示一个弹出窗口，要求你同意设置这些Cookie。通过同意放置Cookies，你可以让我为你提供最佳的体验和服务。如果你愿意，你可以通过浏览器设置关闭本站的Cookie来拒绝同意放置Cookies；但是，我网站的部分功能可能无法完全或按预期运行。你有机会允许和/或拒绝使用Cookie。你可以通过访问浏览器设置随时返回到你的Cookie偏好设置以查看和/或删除它们。</p>
            <p>除了我提供的控件之外，你还可以选择在Internet浏览器中启用或禁用Cookie。大多数互联网浏览器还允许你选择是要禁用所有Cookie还是仅禁用第三方Cookie。默认情况下，大多数互联网浏览器都接受Cookie，但这可以更改。有关详细信息，请参阅Internet浏览器中的帮助菜单或设备随附的文档。</p>
            <p>以下链接提供了有关如何在所有主流浏览器中控制Cookie的说明：</p>
            <ul>
                <li>Google Chrome</li>
                <li> IE</li>
                <li> Safari（桌面版）</li>
                <li> Safari（移动版）</li>
                <li> 火狐浏览器</li>
                <li> Android浏览器</li>
            </ul>
            <p>如你使用其他浏览器，请参阅浏览器制造商提供的文档。</p>
            <p>有关Cookies以及如何管理Cookies的更多信息，请访问：</p>
            <span>wikipedia.org 、 allaboutCookies.org 或 aboutCookies.org</span>
            <h2>更多信息</h2>
            <p>有关我数据处理的更多信息，请参阅我的隐私政策。如果你对此Cookie政策有任何疑问，请通过2523890936@qq.com与我联系。</p>
            <h2>对此Cookie政策的更改</h2>
            <p>我可能对此Cookie政策所做的任何更改都将发布在此页面上。如果更改很重要，我会在我的主页或应用上明确指出该政策已更新。</p>
        </div>
    )
}
export default Cookies