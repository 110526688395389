import styles from './index.module.css'
import {Button, Input, message, Table} from "antd";
import {useEffect, useState} from "react";
import {reqAddFaceClassicType, reqgetFaceClassicTypeList} from "@/api/f/faceClassic";
const FaceClassicType=()=>{
    const [value,setValue]=useState('')
    const userId = JSON.parse(sessionStorage.getItem("userInfo") as string).userId
    const addFaceclassicTypeBtn=()=>{
        let ploay={
            value
        }
        reqAddFaceClassicType(ploay).then(res=>{
            if (res.status){
                message.success("添加成功~")
                setValue('')
            }else {
                message.error("添加失败")
            }
        })
    }
    const [dataSource,setDataSource]:any = useState([])

    const columns = [
        {
            title: '类型名称',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '题目数量',
            dataIndex: 'num',
            key: 'num',
        },
    ];
    useEffect(() => {
        reqgetFaceClassicTypeList({userId}).then(res=>{
            if (res.status){
                setDataSource(res.data)
            }
        })
    },[])
    return (
        <div className={styles['main-box']}>
            <div className={styles['headerTop']}>
                <Input  style={{width:250}} value={value} onChange={(e:any)=>setValue(e.target.value)} />
                <Button onClick={addFaceclassicTypeBtn}>添加</Button>
            </div>

            <Table dataSource={dataSource} columns={columns} />
        </div>
    )
}
export default FaceClassicType